import React from 'react';
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
    render() {
        return (
            <footer className='Footer-Container'>
                <div className='Footer-Socials'>
                    Find me on:
                    <a href='https://instagram.com/devesh.patel.13/'>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href='https://t.me/deveshptl'>
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                </div>
                <p>
                    &#10084; This website is delivered by a server residing in
                    my humble abode. &#x1F609;
                </p>
                <p>Copyright &#169; {new Date().getFullYear()} Devesh Patel</p>
            </footer>
        );
    }
}

export default Footer;

import React from 'react';
import withRouter from '../Navigator/Navigator';
import postInfo from '../Consts/post_info.json';
import { Link } from 'react-router-dom';

class Posts extends React.Component {
    handleClick = (to) => {
        let { navigate } = this.props;
        navigate(to);
    };

    render() {
        return (
            <div className='Posts-Container'>
                <h2>Posts</h2>
                <div className='Posts-List-Container'>
                    <div className='Post-Item'>
                        <div className='Post-Date'>{postInfo.post3.date}</div>
                        <div className='Post-Title'>
                            <Link to={postInfo.post3.url}>
                                {postInfo.post3.title}
                            </Link>
                        </div>
                    </div>
                    <div className='Post-Item'>
                        <div className='Post-Date'>{postInfo.post2.date}</div>
                        <div className='Post-Title'>
                            <Link to={postInfo.post2.url}>
                                {postInfo.post2.title}
                            </Link>
                        </div>
                    </div>
                    <div className='Post-Item'>
                        <div className='Post-Date'>{postInfo.post1.date}</div>
                        <div className='Post-Title'>
                            <Link to={postInfo.post1.url}>
                                {postInfo.post1.title}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Posts);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import galileo from './galileo.jpg';
import nicotine from './nicotine.jpg';
import flatearth from './flat-earth.jpg';
import meatParadox from './meat-paradox.webp';

class Post2 extends React.Component {
    render() {
        return (
            <div className='Post-Container Post2'>
                <div className='Post-Headers'>
                    <h2 className='Post-Title'>
                        Human Psychology - Resistance to change
                    </h2>
                    <p>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;&nbsp; 07-17-2024
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faTag} />
                        &nbsp;&nbsp; Human Psychology, Resistance to change,
                        Denial to new information
                    </p>
                </div>
                <div className='Post-Content'>
                    <div className='Post-Section'>
                        <p>
                            Have you wondered why some people deny new
                            information, whether it be facts or truth, even
                            though there is enough evidence to prove it? Human
                            psychology tends to resist new information that
                            opposes existing beliefs, requires mental effort to
                            understand, or is upsetting. For example, during the
                            coronavirus pandemic, a lot of people denied the
                            fact that the virus is life threatening. This denial
                            manifests itself in many ways, whether that be
                            refusing to wear a mask, attending large gatherings
                            or washing hands often with soap. We will go over
                            some historic examples of this.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Galileo Promoted the Copernican View</h3>
                        <div className='Post-Image'>
                            <img src={galileo} alt='Galileo Galilei' />
                            <p>Portrait of Galilei by Justus Sustermans</p>
                        </div>
                        <p>
                            For example, in the year 1633, Galileo Galilei, the
                            first person to view the heavens through a
                            telescope, found supporting evidence for the
                            Copernican view that the Universe revolves around
                            the Sun and not the Earth. Although he presented
                            evidence to support this, the Catholic Church denied
                            it, insisting that the Earth was at the center and
                            the Universe revolved around it, calling the
                            contrary view heresy. The same year, he was found
                            guilty and sent to prison.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Nicotine Isn't Addictive</h3>
                        <div className='Post-Image'>
                            <img
                                src={nicotine}
                                alt='Nicotine infecting lungs'
                            />
                            <p>Image by stillsujith via Shutterstock</p>
                        </div>
                        <p>
                            The 1988 report,&nbsp;
                            <i>
                                The Health Consequences of Smoking: Nicotine
                                Addiction: A Report of the Surgeon General
                            </i>
                            ,&nbsp; describes the pharmacologic basis of tobacco
                            addiction and arrives at three major conclusions:
                            cigarettes and other forms of tobacco are addicting.
                            In 1994, CEOs of seven major tobacco companies
                            testified under oath to the U.S. Congress that they
                            did not believe nicotine to be addictive. Yes, you
                            read that right&nbsp;
                            <q>they did not believe nicotine to be addictive</q>
                            .
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>
                            The <q>Meat Paradox</q>
                        </h3>
                        <div className='Post-Image'>
                            <img
                                src={meatParadox}
                                alt='Meat paradox representing a cow'
                            />
                            <p>Image by BrockBastian.com</p>
                        </div>

                        <p>
                            When someone cares for animals yet harms other
                            animals to eat meat, this is called the&nbsp;
                            <q>meat paradox</q>&nbsp;by psychologists. The
                            production of meat can cause suffering to nonhuman
                            animals, which can lead to psychological discomfort
                            and moral disengagement. Moral disengagement occurs
                            when someone chooses not to act on their moral
                            values. People often argue that plants are also
                            living beings and feel pain. However, for a living
                            being to experience pain, it needs to have a nervous
                            system and brain structures. While almost all
                            animals have well-developed nervous systems and
                            brain structures, plants lack these and hence don't
                            experience pain. However, they do exhibit complex
                            responses to damage that are adaptive, automatic,
                            and aimed at survival rather than being indicative
                            of suffering or pain. Every living being on this
                            planet is dependent on others for their survival and
                            must do the minimum necessary to sustain themselves.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Earth is Flat</h3>
                        <div className='Post-Image'>
                            <img src={flatearth} alt='Flat Earth' />
                            <p>Image by Claus Lunau/Science Photo Library</p>
                        </div>
                        <p>
                            Aristotle was among the first to recognize that our
                            planet is a round sphere. He observed lunar eclipses
                            and noticed that only a round sphere could cast a
                            circular shadow. Similarly, Eratosthenes erected a
                            pole in Alexandria, and on the summer solstice, he
                            observed that it cast a shadow, proving that the Sun
                            was not directly overhead but slightly south.
                            Recognizing the curvature of the Earth and knowing
                            the distance between the two cities enabled
                            Eratosthenes to calculate the planet's
                            circumference. Despite so much advacement in science
                            and technology including humanity's achievements of
                            landing on Moon and reaching Mars, there are still
                            some people who believe that the Earth is flat, a
                            concept commonly referred to as the flat Earth
                            theory.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Numbers</h3>
                        <ol>
                            <li>
                                In 2001, 64% of Americans believed that it is
                                extremely important for parents to get their
                                children vaccinated. This number reduced to 54%
                                in 2015.
                            </li>
                            <li>
                                32% of people don't believe that climate change
                                is the result of human activity.
                            </li>
                            <li>
                                Over 10% of U.S. voters believe that the federal
                                government allowed the 9/11 attacks to happen.
                            </li>
                        </ol>
                    </div>
                    <div className='Post-Section'>
                        <h3>Why Denial</h3>
                        <p>
                            There are multiple reasons why people often deny to
                            such new information. Some of them are listed below.
                        </p>
                        <ul>
                            <li>
                                <b>Cognitive Dissonance:</b>&nbsp;People
                                experience discomfort when confronted with
                                information that contradicts their existing
                                beliefs. To reduce this discomfort, they may
                                reject or rationalize the new information rather
                                than change their beliefs.
                            </li>
                            <li>
                                <b>Confirmation Bias:</b>&nbsp;Individuals tend
                                to favor information that confirms their
                                pre-existing beliefs and ignore or dismiss
                                information that challenges them. This bias
                                makes it difficult for people to accept new
                                interpretations that conflict with what they
                                already believe.
                            </li>
                            <li>
                                <b>Authority and Trust:</b>&nbsp;People often
                                trust information from authoritative sources
                                such as educators, historians, and cultural
                                institutions. When new interpretations come from
                                less familiar sources, they may be viewed with
                                skepticism and rejected.
                            </li>
                            <li>
                                <b>Inertia and Habit:</b>&nbsp;Humans generally
                                prefer stability and predictability. Changes in
                                understanding require effort to revise knowledge
                                and integrate new information, which can be seen
                                as inconvenient or unnecessary.
                            </li>
                        </ul>
                        On the other hand, using denial as a coping mechanism is
                        not a bad idea and can be a temporary and adaptive
                        response to a stressful or overwhelming situation. In
                        the short term, denial can provide individuals with the
                        time and space to adjust to the new reality, process
                        their emotions, and gather their thoughts before
                        confronting the situation head-on. However, when denial
                        becomes a long-term crutch, it can have detrimental
                        consequences.
                    </div>
                    <div className='Post-Section'>
                        <h3>Conclusion</h3>
                        <p>
                            Human psychology often causes people to ignore
                            reality and believe in things that lack scientific
                            or historical evidence, leading them to make
                            irrational decisions. There are other factors in
                            human psychology that often lead humans to make
                            irrational decisions like when a person is in
                            emotional state.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Post2;

import React from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-regular-svg-icons';

class Menu extends React.Component {
    state = {
        lightTheme: true,
    };

    handleClick = (e) => {
        let theme = e.target.checked;
        this.setState({ lightTheme: theme });
        localStorage.setItem('theme', String(theme));
        this.updateTheme(theme);
    };

    componentDidMount = () => {
        let theme = localStorage.getItem('theme');
        if (theme === null) {
            document.querySelector('html').setAttribute('data-theme', 'light');
            this.setState({ lightTheme: true });
            return;
        }
        if (theme === 'light') {
            document.querySelector('html').setAttribute('data-theme', 'light');
            this.setState({ lightTheme: true });
        } else {
            document.querySelector('html').setAttribute('data-theme', 'dark');
            this.setState({ lightTheme: false });
        }
    };

    updateTheme = (lightTheme) => {
        console.log(lightTheme);
        if (lightTheme) {
            localStorage.setItem('theme', 'light');
            document.querySelector('html').setAttribute('data-theme', 'light');
        } else {
            localStorage.setItem('theme', 'dark');
            document.querySelector('html').setAttribute('data-theme', 'dark');
        }
    };

    render() {
        return (
            <div className='Menu'>
                <div className='Menu-Nav-Links'>
                    <p>
                        <Link to='/'>Home</Link>
                    </p>
                    <p>
                        <Link to='/posts'>Posts</Link>
                    </p>
                    {/* <p>
                        <Link to='/contact'>Contact</Link>
                    </p> */}
                </div>
                <div className='Menu-Theme-Toggle'>
                    <div className='Menu-Theme-Toggle-Text'>Theme</div>
                    <input
                        type='checkbox'
                        className='Theme-Toggle-Checkbox'
                        id='Theme-Toggle-Checkbox'
                        onChange={this.handleClick}
                        checked={this.state.lightTheme}
                    />
                    <label
                        htmlFor='Theme-Toggle-Checkbox'
                        className='Theme-Toggle-Checkbox-Label'
                    >
                        <FontAwesomeIcon icon={faMoon} />
                        <FontAwesomeIcon icon={faSun} />
                        <span className='Theme-Toggle-Ball'></span>
                    </label>
                </div>
            </div>
        );
    }
}

export default Menu;

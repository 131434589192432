import React from 'react';
import '../CSS/NotFoundPage.css'
import { Link } from 'react-router-dom';

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className='NotFoundPage-Container'>
                <h2>404 Not Found</h2>
                <p><span>🔍</span> Uh oh! I can't find what you are looking for...</p>
                <Link to='/'>Head back home</Link>
            </div>
        );
    }
}

export default NotFoundPage;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import CodeBlock from '../UtilComponents/CodeBlock';

const codeblock1 = `subdomain.example.com {
    encode zstd gzip
    import static
    import security
    root * /var/www/subdomain/
    file_server
    log {
            output file /var/log/caddy/subdomain-access.log
            format json
    }
}`;

const codeblock2 = `subdomain2.example.com {
    encode zstd gzip
    import static
    import security
    reverse_proxy localhost:6789
    log {
            output file /var/log/caddy/subdomain2-access.log
            format json
    }
}`;

const codeblock3 = `subdomain.example.com {
    encode zstd gzip
    import static
    import security
    root * /var/www/subdomain/
    file_server
    log {
            output file /var/log/caddy/subdomain-access.log
            format json
    }
    handle_errors {
            rewrite * /errors/{err.status_code}.html
            file_server
    }
}`;

class Post1 extends React.Component {
    render() {
        return (
            <div className='Post-Container Post1 Post-Type-Tech'>
                <div className='Post-Headers'>
                    <h2 className='Post-Title'>
                        Self Host Ubuntu Server at Home
                    </h2>
                    <p>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;&nbsp; 06-03-2024
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faTag} />
                        &nbsp;&nbsp; Ubuntu Server, Self-Host, Web-Server
                    </p>
                </div>
                <div className='Post-Content'>
                    <div className='Post-Section'>
                        <p>
                            We will discuss how to self-host a web server using
                            Ubuntu Server at home and make it accessible over
                            the internet. This means the server will be located
                            in your home, connected to the internet, and you
                            will have full control over its privileges. While
                            this guide does not cover every step in detail, it
                            focuses on the most important steps and security
                            measures you should take. This is based on my
                            experience setting up a server. We will cover:
                        </p>
                        <ol>
                            <li>
                                <a href='#1'>Requirements</a>
                            </li>
                            <li>
                                <a href='#2'>
                                    Download and install ubuntu server
                                </a>
                            </li>
                            <li>
                                <a href='#3'>SSH setup</a>
                            </li>
                            <li>
                                <a href='#4'>Firewall setup</a>
                            </li>
                            <li>
                                <a href='#5'>Fail2ban setup</a>
                            </li>
                            <li>
                                <a href='#6'>Buy a cheap domain name</a>
                            </li>
                            <li>
                                <a href='#7'>Caddy web server setup</a>
                            </li>
                            <li>
                                <a href='#8'>Handling errors</a>
                            </li>
                            <li>
                                <a href='#9'>Rate limit requests</a>
                            </li>
                            <li>
                                <a href='#10'>
                                    Configure router for port forwarding
                                </a>
                            </li>
                            <li>
                                <a href='#11'>DDNS setup</a>
                            </li>
                            <li>
                                <a href='#12'>Analyzing web traffic</a>
                            </li>
                            <li>
                                <a href='#13'>References</a>
                            </li>
                        </ol>
                    </div>
                    <div className='Post-Section' id='1'>
                        <h3>Requirements</h3>
                        <p>
                            I have listed some of the basic requirements and
                            resources you will need to self-host a server.
                        </p>
                        <ol>
                            <li>
                                A spare computer which will be your server
                                running 24x7 and connected to internet.
                            </li>
                            <li>
                                You will need admin rights to alter
                                configurations on your router.
                            </li>
                            <li>
                                A usb drive of at least 2GB for ubuntu
                                installation.
                            </li>
                        </ol>
                    </div>
                    <div className='Post-Section' id='2'>
                        <h3>Download and install ubuntu server</h3>
                        <p>
                            Every developer out there knows how to install an
                            OS. Download ubuntu server from
                            <a href='https://ubuntu.com/download/server'>
                                https://ubuntu.com/download/server
                            </a>
                            and create bootable drive using
                            <a href='https://rufus.ie/en/'>
                                https://rufus.ie/en/
                            </a>
                            . You are now ready to install ubuntu server on your
                            computer. If you don't know how to install, you can
                            refer "Install Ubuntu Server 20.04 LTS" part of this
                            youtube video
                            <a href='https://www.youtube.com/watch?v=9KFlxVTPicc&t=543s'>
                                https://www.youtube.com/watch?v=9KFlxVTPicc&t=543s
                            </a>
                            .
                        </p>
                    </div>
                    <div className='Post-Section' id='3'>
                        <h3>SSH Setup</h3>
                        <p>
                            Assuming you enabled OpenSSH server during the
                            installation of ubuntu server, you will be able to
                            access your server via SSH. If not you will have to
                            install OpenSSH directly while logged in to the
                            server. One most important thing to do on SSH is
                            disable password login and enable key based
                            authentication with 2FA enabled. You also should
                            disable root login. You can do so by configuring the
                            <span className='Code-Inline'>
                                /etc/ssh/ssh_config
                            </span>
                            and
                            <span className='Code-Inline'>
                                /etc/ssh/sshd_config
                            </span>
                            files.
                        </p>
                    </div>
                    <div className='Post-Section' id='4'>
                        <h3>Firewall Setup</h3>
                        <p>
                            One of the widely used firewalls in Ubuntu world is
                            the Uncomplicated Firewall (ufw). Download and
                            install ufw. Once installed, block all incoming and
                            allow all outgoing connections. Now only allow
                            incoming connections to port/s you want to allow.
                            For now, only SSH (22) should be enough. Once you
                            have your web server (nginx/apache/caddy) ready, you
                            will allow ports 80 and 443 for http and https
                            respectively. Remember, if you ever open any other
                            ports, make sure you have enabled strict and secure
                            authentication if needed. Especially when opening DB
                            ports to outside network.
                        </p>
                    </div>
                    <div className='Post-Section' id='5'>
                        <h3>Fail2ban setup</h3>
                        <p>
                            Fail2ban can be used to monitor logs of different
                            services and ban clients that repeatedly match some
                            kind of a pattern or ban based on repeatedly fail
                            authetication attempts. Install fail2ban and enable
                            it for ssh. The configuration files are located
                            under
                            <span className='Code-Inline'>/etc/fail2ban/</span>
                        </p>
                    </div>
                    <div className='Post-Section' id='6'>
                        <h3>DNS Setup</h3>
                        <p>
                            Before we install and setup caddy server, buy a
                            cheap domain name and setup the A records to point
                            to your public IP. You can find your public IP by
                            searching on google <q>what is my ip</q>. So when
                            someone sends request to your website, it comes to
                            your router.
                        </p>
                    </div>
                    <div className='Post-Section' id='7'>
                        <h3>Caddy web server setup</h3>
                        <p>
                            There are many widely used web-servers out there
                            like apache and nginx. One of the main reasons why I
                            chose caddy is because of its simplicity and use of
                            SSL out of the box. Install caddy and all of your
                            caddy configuration files will be under
                            <span className='Code-Inline'>/etc/caddy/</span>.
                            You can configure caddy for each sub-domain in
                            separate files. For example:
                        </p>
                        <CodeBlock code={codeblock1} />
                        <p>
                            This would serve static files from
                            <span className='Code-Inline'>
                                /var/www/subdomain/
                            </span>
                            . You can also forward the incoming requests to a
                            server locally running on some port. For example
                        </p>
                        <CodeBlock code={codeblock2} />
                        <p>
                            This would forward all incoming requests on
                            <span className='Code-Inline'>
                                subdomain2.example.com
                            </span>
                            to
                            <span className='Code-Inline'>localhost:6789</span>.
                        </p>
                    </div>
                    <div className='Post-Section' id='8'>
                        <h3>Handling errors</h3>
                        <p>
                            It is important to handle errors and bad requests
                            gracefully in your web-server and show the right
                            content to the user. If you are forwarding requests
                            to one of your servers running locally on a port,
                            you could easily configure your server to respond
                            with right text. However, if you have configured
                            caddy to serve static files, you will have to setup
                            error handling for different status codes. I choose
                            to have separate html files for each type of status
                            codes. Your configuration would look something like
                            this:
                        </p>
                        <CodeBlock code={codeblock3} />
                        <p>
                            If there was an error, the caddy server will look
                            for an html file with name matching the error status
                            code from
                            <span className='Code-Inline'>
                                /var/www/errors/subdomain/
                            </span>
                            .
                        </p>
                    </div>
                    <div className='Post-Section' id='9'>
                        <h3>Rate-limit requests</h3>
                        <p>
                            There are multiple ways to ratelimit requests on
                            your web-server.
                        </p>
                        <ol>
                            <li>
                                You can configure web-server (caddy in our case)
                                to limit requests. OR
                            </li>
                            <li>
                                You can configure fail2ban to limit requests. I
                                prefer this option over the first one as the
                                requests coming in wouldn't even hit the
                                web-server and it would be blocked. However, I
                                haven't figured out if there is a way to
                                configure custom responses to such requests.
                            </li>
                        </ol>
                        <p>
                            For the first option, you can refer caddy rate-limit
                            documentation page. There are 2 unofficial packages
                            available. For the second option, you can setup
                            fail2ban to ratelimit connections on http/s. You can
                            do so by creating a new filter under
                            <span className='Code-Inline'>
                                /etc/fail2ban/filter.d/
                            </span>
                            and using this filter to setup a new jail in your
                            <span className='Code-Inline'>
                                /etc/fail2ban/jail.local
                            </span>
                            file.
                        </p>
                    </div>
                    <div className='Post-Section' id='10'>
                        <h3>Configure router for port forwading</h3>
                        <p>
                            Now, it is time to setup router to allow incoming
                            connection from outside your local network. So far,
                            you were only able to access what is on your server
                            from within your local network. Read your router's
                            manual and there should be an option to setup port
                            forwarding. As per this blog, we have setup SSH and
                            HTTP/S. So you will have to setup port forwarding
                            for each separately that is for ports 22 (SSH) and
                            80/443 (HTTP/S). Once this is done, find out your
                            public IP and you should be able access your
                            homepage.
                        </p>
                    </div>
                    <div className='Post-Section' id='11'>
                        <h3>DDNS setup</h3>
                        <p>
                            The public IP you currently have might change over
                            time. Dynamic DNS automatically updates your DNS
                            records when an IP address changes. Read
                            documentation of your DNS provider and they should
                            have an API which you can call to update DNS
                            records. I have a bash script to do this which run
                            periodically to confirm the DNS records are pointing
                            to correct IP.
                        </p>
                    </div>
                    <div className='Post-Section' id='12'>
                        <h3>Analyzing web traffic</h3>
                        <p>
                            We would now like to know where all the traffic for
                            our websites is coming from and block any
                            unwated/suspicious IPs. I use
                            <a href='https://goaccess.io/'>
                                https://goaccess.io/
                            </a>
                            for this purpose. Once installed, you can create a
                            report html using
                            <span className='Code-Inline'>
                                sudo goaccess /var/log/caddy/*-access.log
                                --log-format=CADDY -o report.html
                            </span>
                            . I recommend going through the documentation page
                            and use the options as needed.
                        </p>
                    </div>
                    <div className='Post-Section' id='13'>
                        <h3>References</h3>
                        <p>
                            Setting up a complete server is not one day task. It
                            requires continuous efforts and improvements over
                            time. This explains why we didn't go over each
                            section in detail. Otherwise, it would have make
                            this blog really long. I am attaching some of the
                            important resources that really helped me setup the
                            server.
                        </p>
                        <ol>
                            <li>
                                <a href='https://www.youtube.com/watch?v=9KFlxVTPicc'>
                                    https://www.youtube.com/watch?v=9KFlxVTPicc
                                </a>
                            </li>
                            <li>
                                <a href='https://www.youtube.com/watch?v=sO-afVsDJOA&t=0s'>
                                    https://www.youtube.com/watch?v=sO-afVsDJOA&t=0s
                                </a>
                            </li>
                            <li>
                                <a href='https://github.com/mholt/caddy-ratelimit'>
                                    https://github.com/mholt/caddy-ratelimit
                                </a>
                            </li>
                            <li>
                                <a href='https://www.udemy.com/course/deploying-a-secure-virtual-private-server-with-ubuntu-2004/'>
                                    https://www.udemy.com/course/deploying-a-secure-virtual-private-server-with-ubuntu-2004/
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Post1;

import React from 'react';
import './Post3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import ghanshyama from './ghanshyam-a.jpg';
import ghanshyamb from './ghanshyam-b.jpg';
import durvasa from './durvasa-muni.jpg';
import vasudevmahatmya from './vasudevmahatmya.png';
import nilkanthvarni from './nilkanth-varni.jpg';
import markandeya from './markandeya.jpg';

class Post3 extends React.Component {
    render() {
        return (
            <div className='Post-Container Post3'>
                <div className='Post-Headers'>
                    <h2 className='Post-Title'>Birth of Lord Swaminarayan</h2>
                    <p>
                        <FontAwesomeIcon icon={faClock} />
                        &nbsp;&nbsp; 08-19-2024
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faTag} />
                        &nbsp;&nbsp; Lord Swaminarayan, Ghanshyam Maharaj,
                        Sahajanand Swami
                    </p>
                </div>
                <div className='Post-Content'>
                    <div className='Post-Section'>
                        <div className='Post-Image'>
                            <img src={ghanshyama} alt='' />
                            <p>
                                https://www.instagram.com/bapssydney/p/C51sn-MyYHJ
                            </p>
                        </div>
                        <p>
                            Ghanshyam Maharaj or Sahajanand Swami or widely know
                            as Lord Swaminarayan was born on April 3, 1781 CE
                            (Chaitra Sud Nom, Vikram Samvat Year 1837) in
                            Chappaiya village located near Ayodhya, UP, India.
                            His childhood name was Ghanshyam and his parents
                            were Dharmadev (father) and Bhaktimata (mother).
                        </p>
                        <p>
                            By the time he was 10 years old, he had mastered all
                            the Hindu scriptures—a feat that many great saints
                            and scholars strive for their entire lives but often
                            cannot achieve. After serving his parents with full
                            devotion and following their passing, he left home
                            at the age of 11 to undertake severe austerities in
                            the wild, snowy regions of the Himalayas. Clad in
                            just a single piece of cloth, carrying a small stone
                            symbolizing God, and a handheld earthen pot, he
                            embarked on this journey for the betterment of
                            humanity and to spread the pure and righteous
                            teachings of worshipping God. At the very young age
                            of 20 years and 7 months, he received diksha
                            (spiritual initiation) from Ramanand Swami in
                            Western India.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>History before the birth of Lord Swaminarayan</h3>
                        <div className='Post-Image'>
                            <img
                                src={durvasa}
                                alt='Durvasa Muni cursing in Badrikashram'
                            />
                            <p>https://shriji.org/our-sampraday/</p>
                        </div>
                        <p>
                            Hundreds of years ago, when the burden of sins on
                            Earth became unbearable, the saints could no longer
                            tolerate the condition. With hearts full of
                            compassion, 139 saints, including Marichi, Harit,
                            Gautam, Bhrigu, and Bhardwaj, traveled to
                            Badrikashram to have the divine darshan of Lord
                            Nar-Narayan. There, all the saints bathed in the
                            holy river Ganga and bowed down to Lord Nar-Narayan.
                        </p>
                        <p>
                            Lord Narayan asked them,
                            <q>Oh, Rishis, What is the news of the earth?</q>
                            <q>
                                Lord, the burden of sin has greatly increased on
                                the earth. Immoral behavior has increased leaps
                                and bounds. Hypocrisy and pretense prevail
                                everywhere in the name of religion. Innocent
                                people have no leaders to lead and look after
                                them.
                            </q>
                            replied the saints.
                        </p>
                        <p>
                            Dharmadev and Bhaktimata also arrived during this
                            discussion. Lord Nar-Narayan informed them about the
                            earth's situation. Everyone was engrossed in
                            discussing and hearing about this news when Durvasa
                            Muni, a saint full of anger and rage, arrived.
                            However, everyone being so engrossed, no one
                            welcomed him and he felt insulted.
                        </p>
                        <p>
                            His anger came out and cursed everyone present
                            there:
                            <q>
                                You have disregarded me for the worry of earth.
                                Thus, you all go and live there on earth and
                                suffer from the evil spirits.
                            </q>
                            Everyone including Lord Nar-Narayan became aware of
                            the arrival Durvasa Muni. They prayed to Durvasa
                            Muni to take back the curse and have mercy on them.
                            Durvasa Muni replied
                            <q>
                                My curse will not fail, but as a result of my
                                curse, the sins on earth will decrease by your
                                actions.
                            </q>
                        </p>
                        <p>
                            Lord Narayan gently smiled and said
                            <q>
                                the curse is the result of my wish. The curse of
                                saint is not a curse, but is a boon in disguise.
                                I will decrease the burden of sin on the earth
                                and will be born as the son of Dharam and Bhakti
                                for the establishment of true religion.
                            </q>
                            Everyone sighed with relief on hearing this.
                        </p>
                        <p>
                            An abstract of this event is written in Vasudev
                            Mahatamya (one of the chapters in Skanda Purana) by
                            Ved Vyasa who is known for classifying vedas into
                            four forms and authoring some of the most important
                            Hindu scriptures like Bhagavata Purana, and
                            Mahabharat. Skanda Purana consists of 7 sections (or
                            khandas). One of these sections is Visnu Khanda
                            which further consists of 9 sections. Out of these 9
                            sections, Vasudev Mahatamya is one which is composed
                            of 32 chapters. An excerpt from one of the chapter
                            is attached below.
                        </p>
                        <div className='Post-Image'>
                            <img
                                src={vasudevmahatmya}
                                alt='Excerpt from Vasudev Mahatmya'
                            />
                            <p>
                                https://archive.org/details/vasudev-mahatmay-gujarati
                            </p>
                        </div>
                        <p>
                            In this short script, Lord Purna Purushottam (the
                            ultimate god) tells Naradji about one of his
                            avatar's.
                        </p>
                        <blockquote>
                            When the Asuras, who were defeated by Arjuna and me
                            on the battlefield of Mahabharat, are reborn and
                            spread adharma on Earth, I shall incarnate myself.
                            Oh Brahma, at that time, I will take birth
                            <i>
                                <b>through</b>
                            </i>
                            Nar-Narayan in the home of a Samavedi Brahmin
                            family, Dharmadev and Murtidevi, in the Kaushal
                            region of India. Oh Brahma, I will protect the Munis
                            from wicked saints and kings and establish pure and
                            perfect dharma.
                        </blockquote>
                    </div>
                    <div className='Post-Section'>
                        <h3>Birth of Ghanshyam</h3>
                        <div className='Post-Image'>
                            <img
                                src={ghanshyamb}
                                alt='Baby Lord Ghanshyam next to his mother'
                            />
                            <p>
                                https://londonmandir.baps.org/images/bhagwan-swaminarayan-3/
                            </p>
                        </div>
                        <p>
                            It was the most auspicious day in the history of
                            Earth, as Lord Purna Purushottam himself was about
                            to be born. A small house in the town of Chhapaiya,
                            near Ayodhya in Uttar Pradesh, India, was aglow with
                            divine light and filled with melodious music. Late
                            at night, everyone had gathered eagerly, awaiting
                            the birth of the baby. The house belonged to
                            Dharmadev, and it was beautifully decorated with
                            candles, intricate floor patterns with vivid colors
                            at the entrance, and garlands of flowers. The scent
                            of flowers and the rhythmic sounds of various
                            musical instruments spread a sense of divinity
                            throughout the village. Children were playing
                            joyfully, chasing one another.
                        </p>
                        <p>
                            At 10:10 pm on April 3, 1781 CE (Chaitra Sud Nom,
                            Vikram Samvat Year 1837), everyone heard the sound
                            of a baby's cry. It was the happiest day in the
                            lives of Dharmadev and Bhaktimata, as the Lord
                            himself had been born as their child. Deities
                            showered flowers from the sky, expressing their joy.
                            After helping Bhaktimata, the women gathered around
                            the baby, marveling at his uncanny resemblance to
                            his parents and commenting on his divine appearance
                            and joyous face. The men congratulated Dharmadev on
                            the birth of his son.
                        </p>
                        <div className='Post-Image'>
                            <img
                                src={nilkanthvarni}
                                alt='Nilkanth Varni standing on one leg performing meditation'
                            />
                            <p>
                                https://londonmandir.baps.org/images/bhagwan-swaminarayan-3/
                            </p>
                        </div>
                        <p>
                            At that time, no one knew that this baby was Lord
                            Purna Purushottam himself, who would go on to
                            perform determined and unwavering austerities,
                            spread the purest form of religion ever, dispel
                            blind faiths, and transform evil souls throughout
                            his life.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Naming rituals by Markandey Rushi</h3>
                        <div className='Post-Image'>
                            <img
                                src={markandeya}
                                alt='Markandeya performing naming rituals'
                            />
                            <p>
                                https://www.swaminarayanbhagwan.org/about-us/god
                            </p>
                        </div>
                        <p>
                            One day, Markandeya Rishi arrived at Dharmadev's
                            residence, appearing as a Brahmin. Dharmadev warmly
                            welcomed him, and upon realizing that the Rishi was
                            an astrologer, he said,
                            <q>
                                O Maharaj, please perform my son's naming
                                ritual. He is now three months old.
                            </q>
                        </p>
                        <p>
                            Hearing Dharmadev's request, Markandeya Rishi was
                            pleased and named the baby<q>Ghanshyam</q>. He then
                            added,
                            <q>
                                Since he was born under the Karka Raashi, I
                                shall also name him Hari.
                            </q>
                        </p>
                        <p>
                            The Rishi continued,
                            <q>
                                Your son's complexion is dark, which will draw
                                the minds of his followers to him. Additionally,
                                as he was born in the month of Chaitra, he will
                                be renowned by the name Krishna. Combining these
                                names, he shall be called Harikrishna.
                            </q>
                        </p>
                        <p>
                            Upon examining the palms and soles of the baby's
                            feet, which were adorned with various symbols such
                            as an octagon, fish, and half-moon, he said,
                            <q>
                                These signs indicate that your son will lead
                                thousands. In the realms of asceticism,
                                austerity, knowledge, yoga, and religion, he
                                will be comparable to Lord Shiva. Therefore, he
                                will also be known as Nilkanth.
                            </q>
                        </p>
                        <p>
                            He further added,
                            <q>
                                Moreover, your son possesses many benevolent
                                qualities. He will guide many people to his
                                divine abode through meditational trances and
                                inspire them to follow him. He will protect you
                                from all calamities.
                            </q>
                        </p>
                        <p>
                            After the naming ritual was completed, Dharmadev
                            offered gifts and clothes to the Rishi, who accepted
                            them with pleasure. As the Rishi left Dharmadev's
                            home, he entered a trance induced by Ghanshyam
                            Maharaj. In the trance, Ghanshyam Maharaj said to
                            him,
                            <q>
                                O Muni, in due time, come to the Gujarat region
                                where you shall meet me.
                            </q>
                        </p>
                        <p>
                            In this way, summoned by Ghanshyam Maharaj through
                            the trance, Markandeya Rishi later arrived in
                            Gujarat and met Shreeji Maharaj in the town of
                            Unjha. He was then initiated with the Maha Bhagwati
                            Diksha and renamed Mahanubhavanand Swami by Lord
                            Swaminarayan.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>Conclusion</h3>
                        <p>
                            This marks the beginning of the divine life of Lord
                            Purna Purushottam on Earth. From his childhood,
                            Ghanshyam began displaying divine powers and
                            performing leelas, transforming the lives of many
                            souls and guiding them toward the righteous path of
                            living.
                        </p>
                    </div>
                    <div className='Post-Section'>
                        <h3>References</h3>
                        <p>
                            Please note that I encourage everyone to read our
                            Hindu scriptures directly, as this is just a summary
                            of several articles I've read. One cannot gain
                            complete understanding by reading only a summarized
                            article, especially when it comes to matters of
                            religion and faith.
                        </p>
                        <ol>
                            <li>
                                <a href='https://archive.org/details/vasudev-mahatmay-gujarati'>
                                    Vasudev Mahatmya - Gujarati - Highly
                                    recommended to read
                                </a>
                            </li>
                            <li>
                                <a href='https://www.swaminarayan.faith/media/3834/vasudev-mahatmya-english.pdf'>
                                    Vasudev Mahatmya - English - Translated
                                    might not be 100% correct
                                </a>
                            </li>
                            <li>
                                <a href='https://www.swaminarayan.faith/short-stories/curse-of-durvasa'>
                                    Curse of Durvasa Muni
                                </a>
                            </li>
                            <li>
                                <a href='https://www.rajkotgurukul.org/activity-details/lord-swaminarayan/a-ODE0'>
                                    Lord Swaminarayan Info - Rajkot Gurukul
                                </a>
                            </li>
                            <li>
                                <a href='https://en.wikipedia.org/wiki/Skanda_Purana'>
                                    Skanda Purana
                                </a>
                            </li>
                            <li>
                                <a href="https://www.swaminarayan.info/our-sampraday/swaminarayan-faith/ghanshyam-charitra/markanday-names-ghanshyam#:~:text=Hearing%20Dharmadev's%20request%2C%20Markanday%20rishi,I%20shall%20name%20him%20Hari.%E2%80%9D">
                                    Markanday names Ghanshyam
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}

export default Post3;

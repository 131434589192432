import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Menu from './Components/Menu/Menu';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import Posts from './Components/Posts/Posts';
import Post1 from './Components/Post1/Post1';
import Post2 from './Components/Post2/Post2';
import Post3 from './Components/Post3/Post3';
import NotFoundPage from './Components/NotFoundPage/NotFoundPage';
import postInfo from './Components/Consts/post_info.json';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

function App() {
    return (
        <div className='App'>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route
                        path='/'
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <Home />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path='/posts'
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <Posts />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path={postInfo.post1.url}
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <Post1 />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path={postInfo.post2.url}
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <Post2 />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path={postInfo.post3.url}
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <Post3 />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                    <Route
                        path='/*'
                        Component={() => (
                            <React.Fragment>
                                <Menu />
                                <NotFoundPage />
                                <Footer />
                            </React.Fragment>
                        )}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

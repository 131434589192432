import React from 'react';
import withRouter from '../Navigator/Navigator';
import postInfo from '../Consts/post_info.json';
import { Link } from 'react-router-dom';

class Home extends React.Component {
    handleClick = (to) => {
        let { navigate } = this.props;
        navigate(to);
    };

    render() {
        return (
            <div className='Home'>
                <div className='About'>
                    <h1>About</h1>
                    <p>
                        Hi, I'm Devesh. I am a Software Engineer who loves
                        reading and exploring new technology. I don't know what
                        I am doing with this blog. But hoping to share my
                        thoughts, perspective and day to day life experiences.
                        You'll find posts about the latest books I've read,
                        technologies I've explored, and my thoughts and
                        experiences from everyday life, including reflections on
                        spirituality. Whether you're a fellow tech enthusiast, a
                        book lover, or simply curious about different aspects of
                        life, I hope you find something here that resonates with
                        you.
                    </p>
                </div>
                <div className='Posts-Container'>
                    <h2>Recent Posts</h2>
                    <div className='Posts-List-Container'>
                        <div className='Post-Item'>
                            <div className='Post-Date'>
                                {postInfo.post3.date}
                            </div>
                            <div className='Post-Title'>
                                <Link to={postInfo.post3.url}>
                                    {postInfo.post3.title}
                                </Link>
                            </div>
                        </div>
                        <div className='Post-Item'>
                            <div className='Post-Date'>
                                {postInfo.post2.date}
                            </div>
                            <div className='Post-Title'>
                                <Link to={postInfo.post2.url}>
                                    {postInfo.post2.title}
                                </Link>
                            </div>
                        </div>
                        <div className='Post-Item'>
                            <div className='Post-Date'>
                                {postInfo.post1.date}
                            </div>
                            <div className='Post-Title'>
                                <Link to={postInfo.post1.url}>
                                    {postInfo.post1.title}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Home);

import React from 'react';
import '../CSS/CodeBlock.css';

class CodeBlock extends React.Component {
    render() {
        return (
            <pre className='CodeBlock-Container'>
                <code>{this.props.code}</code>
            </pre>
        );
    }
}

export default CodeBlock;
